/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:18:31
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 17:25:26
 */
import AppCrowdattribute from './Crowdattribute.vue'
export default AppCrowdattribute
