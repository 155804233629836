/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:18:45
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-03 11:27:41
 */
import { createNamespacedHelpers } from 'vuex'
import PageHeader from './components/pageHeader'
import PageMain from './components/pageMain'
import { getNormalWeek } from '@/plugins/utils'

const { mapActions, mapGetters } = createNamespacedHelpers('App/Datapackage/Crowdattribute')

export default {
  name: 'AppCrowdattribute',
  components: {
    PageHeader,
    PageMain
  },
  computed: {
    ...mapGetters([
      'queryForm'
    ])
  },
  async mounted () {
    // 初始化请求参数
    const updateForm = {}
    const { activity_id: activityId, package_id: packageId } = this.$route.query
    if (!activityId && !packageId) {
      this.$wToast.error('缺少请求参数')
      return
    }
    if (activityId) {
      const [startDate, endDate] = getNormalWeek(true)
      updateForm._date = [new Date(startDate), new Date(endDate)]
      updateForm.start_date = startDate
      updateForm.end_date = endDate
      updateForm.source_id = activityId - 0
      updateForm.analysis_type = 1
    } else {
      updateForm.source_id = packageId - 0
      updateForm.analysis_type = 2
    }
    await this.updateQueryForm(updateForm)
    this.getTitleFromSession()
    this.getCrowdattributeList()
  },
  methods: {

    ...mapActions([
      'getTitleFromSession',
      'updateQueryForm',
      'getCrowdattributeList' // 获取图表数据
    ])
  }
}
